import type {FC, ReactElement} from 'react'
import {
	createContext, useContext, useState, useMemo, useCallback, useEffect, useRef,
} from 'react'
import {useSearchParams, useParams} from 'react-router-dom'

interface TimeIntervalProviderProps {
    children?: ReactElement,
	initialTimeRange: string,
}

export interface TimeIntervalContextProps {
	startTime: Date,
	endTime: Date,
	timeRange: string,
	updateTimeInterval: (timeRange: string) => void,
	updateStartTime: (startTime: Date) => void,
	updateEndTime: (endTime: Date) => void,
	setTimeInterval: (start: Date, end: Date) => void,
	resetTime: () => void,
}

const TimeIntervalContext = createContext<TimeIntervalContextProps | null>(null)

const useTimeIntervalContext = (): TimeIntervalContextProps | null => useContext(TimeIntervalContext)

const removeHours = (numOfHours: number, date: Date): Date => {
	const dateCopy = new Date(date.getTime())
	dateCopy.setTime(dateCopy.getTime() - (numOfHours * 60 * 60 * 1000))
	return dateCopy
}

const getStartTime = (timeRange: string): Date => {
	const now = new Date(Date.now())
	let startDate: Date
	switch (timeRange) {
		case '00:15:00':
			startDate = removeHours(0.25, now)
			break
		case '01:00:00':
			startDate = removeHours(1, now)
			break
		case '08:00:00':
			startDate = removeHours(8, now)
			break
		case '1 00:00:00':
			startDate = removeHours(24, now)
			break
		case '7 00:00:00':
			startDate = removeHours(168, now)
			break
		case '28 00:00:00':
			startDate = removeHours(672, now)
			break
		case 'three-months':
			startDate = removeHours(2016, now)
			break
		case 'six-months':
			startDate = removeHours(4032, now)
			break
		case 'twelve-months':
			startDate = removeHours(8736, now)
			break
		default:
			startDate = removeHours(24, now)
			break
	}
	return startDate
}

const TimeIntervalProvider: FC<TimeIntervalProviderProps> = ({children, initialTimeRange}): ReactElement => {
	// States
	const [searchParams, setSearchParams] = useSearchParams()
	const timeRangeParam = searchParams.get('time_range')
	const [startTime, setStartTime] = useState<Date>(searchParams.get('start_date') ? new Date(searchParams.get('start_date') as string) : getStartTime(initialTimeRange))
	const [endTime, setEndTime] = useState<Date>(searchParams.get('end_date') ? new Date(searchParams.get('end_date') as string) : new Date())
	const [timeRange, setTimeRange] = useState<string>(timeRangeParam || initialTimeRange)

	// Hooks
	const {id} = useParams()

	// Refs
	const startTimeRef = useRef<string>(startTime.toISOString())
	const endTimeRef = useRef<string>(endTime.toISOString())
	const idRef = useRef<string>(id as string)
	const timeRangeRef = useRef<string>(timeRange)

	// Functions
	const updateStartTime = useCallback((startTime: Date) => {
		setStartTime(startTime)
	}, [])

	const updateEndTime = useCallback((endTime: Date) => {
		setEndTime(endTime)
	}, [])

	const updateTimeInterval = useCallback((timeRange: string) => {
		setTimeRange(timeRange)
		if (timeRange === 'null') return
		const now = new Date(Date.now())
		const startDate = getStartTime(timeRange)
		setEndTime(now)
		setStartTime(startDate)
	}, [])

	const setTimeInterval = useCallback((start: Date, end: Date) => {
		setStartTime(start)
		setEndTime(end)
		setTimeRange('custom')
	}, [])

	const resetTime = useCallback(() => {
		const now = new Date(Date.now())
		const newStart = getStartTime(timeRange)
		setStartTime(newStart)
		setEndTime(now)
	}, [timeRange])

	// Effects
	useEffect(() => {
		// const {current: currentId} = idRef
		const {current: currentStartTime} = startTimeRef
		const {current: currentEndTime} = endTimeRef
		const {current: currentTimeRange} = timeRangeRef
		const newSearchParams = new URLSearchParams(searchParams)
		if (currentStartTime !== startTime.toISOString() || currentStartTime !== searchParams.get('start_date')) {
			newSearchParams.set('start_date', startTime.toISOString())
			startTimeRef.current = startTime.toISOString()
		}
		if (currentEndTime !== endTime.toISOString() || currentEndTime !== searchParams.get('end_date')) {
			newSearchParams.set('end_date', endTime.toISOString())
			endTimeRef.current = endTime.toISOString()
		}
		if (currentTimeRange !== timeRange || currentTimeRange !== searchParams.get('time_range')) {
			newSearchParams.set('time_range', timeRange)
			timeRangeRef.current = timeRange
		}
		setSearchParams(newSearchParams, {replace: true})
	}, [timeRange, startTime, endTime, id, searchParams, setSearchParams])

	useEffect(() => {
		const {current: currentId} = idRef
		if (currentId !== id) {
			updateTimeInterval(initialTimeRange)
		}
		idRef.current = id as string
	}, [id, updateTimeInterval, initialTimeRange])

	return (
		<TimeIntervalContext.Provider
			value={
				useMemo(() => ({
					startTime,
					endTime,
					timeRange,
					updateTimeInterval,
					updateStartTime,
					updateEndTime,
					setTimeInterval,
					resetTime,
				}), [
					startTime,
					endTime,
					timeRange,
					updateTimeInterval,
					updateStartTime,
					updateEndTime,
					setTimeInterval,
					resetTime,
				])
			}
		>
			{children}
		</TimeIntervalContext.Provider>
	)
}

export {
	useTimeIntervalContext, TimeIntervalProvider, TimeIntervalContext,
}

import type {FC, ReactElement} from 'react'
import {
	lazy, StrictMode, Suspense, useEffect,
} from 'react'
import {
	Route, Routes, useLocation,
} from 'react-router-dom'

import GlobalUsageTracker from '../../shared/components/usage/GlobalUsageTracker'

// Import templates
import AuthTemplate from '../../shared/templates/AuthTemplate'
import DashTemplate from '../../shared/templates/DashTemplate'
import MinimizedTemplate from '../../shared/templates/MinimizedTemplate'
import type {UserContextProps} from './UserProvider'
import {useUserContext} from './UserProvider'
import type {AuthContextProps} from './AuthProvider'
import {useAuthContext} from './AuthProvider'
import SettingsLoader from '../../shared/components/loaders/dashboards/Settings'
import LinesOverviewLoader from '../../shared/components/loaders/dashboards/LinesOverview'
import LineSignalsLoader from '../../shared/components/loaders/dashboards/LineSignals'
import DashboardManagementLoader from '../../shared/components/loaders/dashboards/DashboardManagement'
import LineDashboardLoader from '../../shared/components/loaders/dashboards/LineDashboard'
// import LineManagementLoader from '../../shared/components/loaders/dashboards/LineManagement'
import BatchTraceabilityLoader from '../../shared/components/loaders/dashboards/BatchTraceability'
import AuthTemplateLoader from '../../shared/components/loaders/dashboards/AuthTemplate'
import MinimizedTemplateLoader from '../../shared/components/loaders/dashboards/MinimizedTemplate'
import DashTemplateLoader from '../../shared/components/loaders/dashboards/DashTemplate'
// import BasicDashboardContainer from '../../shared/components/BasicDashboardContainer.tsx'
import RowDataProvider from '../Dashboards/EdgeApi/shared/rowDataProvider.tsx'
import AnomalyComponent from '../Dashboards/Anomalies/index.tsx'
import AddConnectionEdgeApiPage from '../Dashboards/EdgeApi/components/connections/addConnectionForm/AddConnectionEdgeApiPageF.tsx'
import {ViewsProvider} from '../../shared/providers/ViewsProvider.tsx'
import {TimeIntervalProvider} from '../../shared/providers/TimeIntervalProvider.tsx'
import {EquipmentsProvider} from '../../shared/providers/EquipmentsProvider.tsx'
import AuthRedirect from '../../shared/components/auth/AuthRedirect.tsx'
import AuthCallbackPage from '../../shared/components/auth/AuthCallbackPage.tsx'
import {isNewAuthSystemEnabled} from '../../apis/auth.ts'
import ExternalBookmarkComponent from '../Dashboards/ExternalBookmarks/index.tsx'
import CommentComponent from '../Dashboards/Comments/index.tsx'

// Lazily load components
const LoginForm = lazy(() => import('../../shared/components/auth/forms/LoginForm'))
const RegisterForm = lazy(() => import('../../shared/components/auth/forms/RegisterForm'))
const LinesOverview = lazy(() => import('../Dashboards/LinesOverview'))
const LineDashboard = lazy(() => import('../Dashboards/LineDashboard'))
const LineSignals = lazy(() => import('../Dashboards/LineSignals'))
// const LineManagement = lazy(() => import('../Dashboards/LineManagement'))
const LineMaintenance = lazy(() => import('../Dashboards/LineMaintenance'))
const Settings = lazy(() => import('../Dashboards/Settings/Settings'))
const InactiveUser = lazy(() => import('../../shared/pages/InactiveUser'))
const BatchTraceability = lazy(() => import('../Dashboards/BatchTraceability'))

// Temporarily removed from now. Uncomment to add it back
// const DashboardManagement = lazy(() => import('../Dashboards/DashboardManagement'))
const SignalsDashboard = lazy(() => import('../Dashboards/SignalsDashboard'))
const NoPage = lazy(() => import('../../shared/pages/NoPage'))
const ComparePDFs = lazy(() => import('../../shared/pages/ComparePDFs'))
const StartupKitOnboarding = lazy(() => import('../../shared/pages/StartupKitOnboarding'))
const Welcome = lazy(() => import('../../shared/pages/Welcome'))
const EdgeApi = lazy(() => import('../Dashboards/EdgeApi'))
const Cockpit = lazy(() => import('../../shared/components/maps/Cockpit'))
const CreateTenantForm = lazy(() => import('../../shared/components/auth/forms/CreateTenantForm'))
// const Signals = lazy(() => import('../Dashboards/LineManagement/components/Signals'))
// const EquipmentManagement = lazy(() => import('../Dashboards/EquipmentManagement'))
const EquipmentDashboard = lazy(() => import('../Dashboards/EquipmentDashboard'))
const ProgramSignalThresholdViolationsTable = lazy(() => import('../Dashboards/LineManagement/components/programSignalThrehsolds/ProgramSignalThresholdViolationsTable.tsx'))

const Router: FC = (): ReactElement => {
	const auth = localStorage.getItem('token') ? localStorage.getItem('token') : null
	const {loadingUser} = useUserContext() as UserContextProps
	const {userIsLoggedIn} = useAuthContext() as AuthContextProps

	// This component is used to scroll to the top of the page when the URL changes
	const ScrollToTop = (): null => {
		const {pathname} = useLocation()

		useEffect(() => {
			window.scrollTo(0, 0)
		}, [pathname])

		return null
	}

	return (
		<StrictMode>
			<GlobalUsageTracker />
			<ScrollToTop />
			<Routes>
				<Route
					path="/authorize-callback"
					element={<AuthCallbackPage />}
				/>
				{/* Routes for unauthenticated users based on new auth system flag */}
				{(!userIsLoggedIn && !loadingUser) ? isNewAuthSystemEnabled ? (
					<Route
						path="/"
						element={(
							<Suspense fallback={<AuthTemplateLoader />}>
								<AuthRedirect>
									<AuthTemplate />
								</AuthRedirect>
							</Suspense>
						)}
					>
						<Route
							path="login"
							element={<LoginForm />}
						/>
						<Route
							path="create-account"
							element={<RegisterForm />}
						/>
					</Route>
				) : (
					<Route
						path="/"
						element={(
							<Suspense fallback={<AuthTemplateLoader />}>
								<AuthTemplate />
							</Suspense>
						)}
					>
						<Route
							path="login"
							element={<LoginForm />}
						/>
						<Route
							path="create-account"
							element={<RegisterForm />}
						/>
					</Route>
				) : null}
				{/* Routes for authenticated users */}
				{auth && loadingUser ? (
					<Route
						path="/*"
						element={<DashTemplateLoader />}
					/>
				) : userIsLoggedIn && !loadingUser ? (
					<>
						<Route
							path="/"
							element={(
								<Suspense fallback={<DashTemplateLoader />}>
									<DashTemplate />
								</Suspense>
							)}
						>
							<Route
								path="connections"
								element={<Suspense fallback={<DashboardManagementLoader />}><RowDataProvider><EdgeApi /></RowDataProvider></Suspense>}
							/>
							<Route
								path="anomalies"
								element={<Suspense fallback={<DashboardManagementLoader />}><AnomalyComponent /></Suspense>}
							/>
							<Route
								path="comments"
								element={<Suspense fallback={<DashboardManagementLoader />}><CommentComponent /></Suspense>}
							/>
							<Route
								path="external_bookmarks"
								element={
									(
										<Suspense fallback={<DashboardManagementLoader />}>
											<ExternalBookmarkComponent />
										</Suspense>
									)
								}
							/>
							{/* <Route
								path="all-signals"
								element={(
									<Suspense fallback={<DashboardManagementLoader />}>
										<BasicDashboardContainer>
											<Signals
												title={false}
												height="calc(100vh - 165px)"
											/>
										</BasicDashboardContainer>
									</Suspense>
								)}
							/> */}
							<Route
								path="threshold-violations"
								element={<ProgramSignalThresholdViolationsTable />}

							/>
							<Route
								path="create-organization"
								element={<CreateTenantForm />}
							/>
							<Route
								path="/"
								element={<Suspense fallback={<LinesOverviewLoader />}><LinesOverview /></Suspense>}
							/>
							<Route
								path="lines_overview"
								element={<Suspense fallback={<LinesOverviewLoader />}><LinesOverview /></Suspense>}
							/>
							<Route
								path="line/:id"
								element={<Suspense fallback={<LineDashboardLoader />}><LineDashboard /></Suspense>}
							/>
							<Route
								path="equipment/:id"
								element={<Suspense fallback={<LineDashboardLoader />}><EquipmentDashboard /></Suspense>}
							/>
							{/* <Route
								path="equipment/:id/equipment_management"
								element={<Suspense fallback={<LineDashboardLoader />}><EquipmentManagement /></Suspense>}
							/> */}
							<Route
								path="line/:id/signals"
								element={<Suspense fallback={<LineSignalsLoader />}><ViewsProvider><TimeIntervalProvider initialTimeRange="1 00:00:00"><EquipmentsProvider><LineSignals /></EquipmentsProvider></TimeIntervalProvider></ViewsProvider></Suspense>}
							/>
							{/* <Route
								path="line/:id/line_management"
								element={<Suspense fallback={<LineManagementLoader />}><LineManagement /></Suspense>}
							/> */}
							<Route
								path="maintenance_calendar/"
								element={<LineMaintenance />}
							/>
							<Route
								path="settings"
								element={<Suspense fallback={<SettingsLoader />}><Settings /></Suspense>}
							/>
							<Route
								path="/inactive_user"
								element={<InactiveUser />}
							/>
							<Route
								path="batch_traceability"
								element={<Suspense fallback={<BatchTraceabilityLoader />}><BatchTraceability /></Suspense>}
							/>
							<Route
								path="*"
								element={<NoPage />}
							/>
						</Route>
						<Route
							path="/"
							element={(
								<Suspense fallback={<MinimizedTemplateLoader />}>
									<MinimizedTemplate />
								</Suspense>
							)}
						>
							<Route
								path="startup-kit-onboarding"
								element={<StartupKitOnboarding />}
							/>
							<Route
								path="connections/add"
								element={<AddConnectionEdgeApiPage />}
							/>
						</Route>
						<Route
							path="welcome"
							element={<Welcome />}
						/>
					</>
				) : null}
				{/* Additional routes for other sections */}
				<Route
					path="/"
					element={(
						<Suspense fallback={<MinimizedTemplateLoader />}>
							<MinimizedTemplate />
						</Suspense>
					)}
				>
					<Route
						path="dashboard/:id"
						element={<SignalsDashboard />}
					/>
					<Route
						path="compare-pdfs"
						element={<ComparePDFs />}
					/>
					<Route
						path="cockpit"
						element={<Cockpit />}
					/>
				</Route>
			</Routes>
		</StrictMode>
	)
}

export default Router

import type {FC} from 'react'
import {Box} from '@mui/material/'
import {useGetEquipmentsQuery, useGetLinesQuery} from '../../../../apis/base'
import {useCustomTranslation} from '../../../../shared/hooks/useCustomTranslation'
import SelectComponent from '../../BatchTraceability/components/SelectComponent'

type ProductionArea = {
	id: string,
	name: string,
}

type Category = {
	id: string,
	name: string,
}

type Props = {
	productionAreas: ProductionArea[],
	categories: Category[],
	currentProductionArea: string,
	setCurrentProductionArea: (value: string) => void,
	currentCategory: string,
	setCurrentCategory: (value: string) => void,
	currentLineId: string,
	setCurrentLineId: (value: string) => void,
	currentEquipmentId: string,
	setCurrentEquipmentId: (value: string) => void,
}

const FilterComments: FC<Props> = ({
	productionAreas,
	categories,
	currentProductionArea, setCurrentProductionArea,
	currentCategory, setCurrentCategory,
	currentLineId, setCurrentLineId,
	currentEquipmentId, setCurrentEquipmentId,
}) => {
	const [tCommon] = useCustomTranslation('common')
	const [tAnomalies] = useCustomTranslation('anomalies')

	const {data: lines, isFetching: isFetchingLines} = useGetLinesQuery({}, {skip: !currentProductionArea})
	const {data: equipments, isFetching: equipmentsIsFetching} = useGetEquipmentsQuery(
		{lineId: currentLineId},
		{skip: !currentLineId},
	)

	const handleChangeProductionArea = (value: string): void => {
		setCurrentProductionArea(value === 'null' ? '' : value)
		setCurrentCategory('')
		setCurrentLineId('')
		setCurrentEquipmentId('')
	}

	const handleChangeCategory = (value: string): void => {
		setCurrentCategory(value === 'null' ? '' : value)
	}

	const handleChangeLine = (value: string): void => {
		setCurrentLineId(value === 'null' ? '' : value)
		setCurrentEquipmentId('')
	}

	const handleChangeEquipment = (value: string): void => {
		setCurrentEquipmentId(value === 'null' ? '' : value)
	}

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				gap: 2,
				width: '100%',
				paddingLeft: 2,
				justifyContent: 'space-between',
				alignItems: 'flex-start',
			}}
			data-testid="mbai-filter-comments"
		>
			{/* Category Filter */}
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					width: '20%',
				}}
			>
				<SelectComponent
					label={tCommon('commentTable.category')}
					isLoading={false}
					data={categories || []}
					value={currentCategory || ''}
					onChange={handleChangeCategory}
					inputProps={{id: 'category-select'}}
					noDataMessage={tCommon('all')}
					displayLabel={false}
				/>
			</Box>
			{/* Hierarchy Filters */}
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					gap: 2,
					width: '65%',
				}}
			>
				<SelectComponent
					label={tCommon('commentTable.production_area')}
					isLoading={false}
					data={productionAreas || []}
					value={currentProductionArea || ''}
					onChange={handleChangeProductionArea}
					inputProps={{id: 'production-area-select'}}
					noDataMessage={tCommon('all')}
					displayLabel={false}
				/>
				<SelectComponent
					label={tAnomalies('line')}
					isLoading={isFetchingLines}
					data={lines || []}
					value={currentLineId || ''}
					onChange={handleChangeLine}
					inputProps={{id: 'line-select'}}
					noDataMessage={tCommon('appbar_search_line.no_results')}
					displayLabel={false}
					disabled={!currentProductionArea || (lines && lines.length === 0)}
				/>
				<SelectComponent
					label={tAnomalies('equipment')}
					isLoading={equipmentsIsFetching}
					data={equipments || []}
					value={currentEquipmentId || ''}
					onChange={handleChangeEquipment}
					inputProps={{id: 'equipment-select'}}
					noDataMessage="No equipments found"
					disabled={!currentLineId || (equipments && equipments.length === 0)}
					displayLabel={false}
				/>
			</Box>
		</Box>
	)
}

export default FilterComments

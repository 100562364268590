import {
	type FC, type ReactElement, type ChangeEvent, type ReactNode,
	useState,
} from 'react'
import type {TextFieldProps} from '@mui/material'
import {
	FormControl, styled, TextField,
} from '@mui/material'
import BasicLabel from './base/BasicLabel.tsx'
import {getInputPadding} from './utils/baseInputs.ts'
import {useCustomTranslation} from '../hooks/useCustomTranslation.ts'

export type BasicInputProps = TextFieldProps & {
	onBasicInputChange?: (value: string) => void,
	label?: ReactNode | string,
	mbaiSize?: 'small' | 'medium' | 'large',
	helperText?: ReactNode | string | null,
	whiteBackground?: boolean,
	displayLabel?: boolean,
	timeSeconds?: boolean,
	height?: string,
    minHeight?: string,
}

export const MBAIBaseInput = styled(TextField)<BasicInputProps>(({theme, ...props}) => ({
	'label + &': {marginTop: theme.spacing(3)},
	'& fieldset': {
		transition: theme.transitions.create([
			'border-color',
			'background-color',
			'box-shadow',
		]),
		border: '2px solid transparent',
	},

	'& .Mui-disabled': {
		backgroundColor: props.whiteBackground ? 'theme.palette.background.paper !important' : 'theme.palette.background.fadedOpaque !important',
		'& fieldset': {borderColor: 'transparent !important'},
		color: theme.palette.text.disabled,
	},

	'& .MuiInputBase-root.Mui-disabled': {
		backgroundColor: props.whiteBackground ? 'theme.palette.background.paper !important' : 'theme.palette.background.fadedOpaque !important',
		'& fieldset': {borderColor: 'transparent !important'},
	},

	'& div.MuiInputBase-root': {
		padding: 0,
		paddingBottom: props.whiteBackground ? '42px' : 0,
		'& .MuiInputAdornment-positionEnd': {paddingRight: '10px'},
		'&:hover': {backgroundColor: props.whiteBackground ? theme.palette.background.paper : theme.palette.divider},
		'&:hover fieldset': {border: '2px solid transparent'},
		'&.Mui-focused fieldset': {border: `2px solid ${theme.palette.primary.main}`},
		'&.MuiInputBase-adornedStart': {'& .MuiSvgIcon-root': {marginLeft: '14px'}},
		'&.MuiInputBase-adornedEnd': {'& .MuiSvgIcon-root': {marginRight: '10px'}},
		backgroundColor: props.whiteBackground ? theme.palette.background.paper : theme.palette.background.fadedOpaque,
	},
	'& input.MuiSelect-nativeInput': {border: 'none'},
	'& input.MuiInputBase-input, & textarea.MuiInputBase-input:not([aria-hidden="true"])': {
		borderRadius: 6,
		position: 'relative',
		fontSize: 14,
		width: '100%',
		height: '1.5rem',
		minHeight: props.minHeight ? props.minHeight : undefined,
		lineHeight: '1.2rem',
		fontFamily: 'Circular Book',
		'& .MuiSvgIcon-root': {right: '8px'},
	},
}))

const StyledFormControl = styled(FormControl)(() => ({
	'& .MuiSvgIcon-root': {right: '8px'},
	'& div.MuiSelect-icon': {
		right: '24px',
		top: 'calc(50% - 7px)',
	},
}))

const BasicInput: FC<BasicInputProps> = (
	{
		onBasicInputChange,
		mbaiSize = 'medium',
		label,
		displayLabel = true,
		timeSeconds,
		height,
		...rest
	},
): ReactElement => {
	const [error, setError] = useState<string | null>(null)
	const [t] = useCustomTranslation('common')
	const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
		let {value} = event.target

		if (timeSeconds) {
			value = value.replace(/[^0-9:]/g, '')

			const time = value.split(':')

			if (time.length > 1) {
				time[1] = time[1].slice(0, 2)
			}

			value = time.join(':')

			const regex = /^\d{2,}:\d{2}$/

			if (!regex.test(value)) {
				setError(t('basic_input.invalid_format'))
				if (onBasicInputChange) onBasicInputChange(value)
				return
			}

			const secondsStr = value.split(':')[1]
			const seconds = Number(secondsStr)
			const minutesStr = value.split(':')[0]
			const minutes = Number(minutesStr)

			if (minutes > 59) {
				setError(t('basic_input.minutes_cannot_be_greater'))
				return
			}

			if (seconds > 59) {
				setError(t('basic_input.seconds_cannot_be_greater'))
				return
			}

			setError(null)
		}

		if (onBasicInputChange) {
			onBasicInputChange(value)
		}
	}

	return (
		<StyledFormControl
			variant="standard"
			fullWidth
			data-testid="mbai-main-basic-input-form-control"
			sx={
				{'& input.MuiInputBase-input, & textarea.MuiInputBase-input:not([aria-hidden="true"])': {padding: `${getInputPadding(mbaiSize)} !important`}}
			}
		>
			{
				displayLabel ? (
					<BasicLabel
						label={label as string}
					/>
				) : null
			}
			<MBAIBaseInput
				data-testid="mbai-main-basic-input"
				onChange={handleInputChange}
				value={rest.value as string}
				placeholder={!displayLabel ? label as string : ''}
				error={!!error}
				helperText={error}
				sx={{
					...rest.sx,
					height: height || 'auto',
				}}
				{...rest}
			/>
		</StyledFormControl>
	)
}

export default BasicInput
